<template>
    <div class="com-portal-wrp wrap">
        <portal-header></portal-header>
        <a-row class="com-portal-content-wrp content" type="flex" justify="space-between">
            <a-col :span="11">
                <news-block :channel-id="dict.newsDict.importantNews" title="重要新闻"></news-block>
            </a-col>
            <a-col :span="11">
                <news-block :channel-id="dict.newsDict.firstLineDynamics" title="一线动态"></news-block>
            </a-col>
        </a-row>
        <portal-footer></portal-footer>
    </div>
</template>

<script>
import Vue from 'vue'
import dict from '@custom/dict'
import api from '@api/article/apiMethod'
const PortalHeader = () => import('../../component/common/PortalHeader.vue')
const NewsBlock = () => import('../../component/common/NewsBlock.vue')
const PortalFooter = () => import('../../component/common/PortalFooter.vue')

export default {
    data() {
      return {
        dict
      }
   },
   components:{
        PortalHeader,
        NewsBlock,
        PortalFooter
   },
   mounted() {},
   activated() {},
   methods: { }
}
</script>

<style lang='less' scoped>
.wrap{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
.content{
    margin: 30px auto;
    flex: 1;
}
</style>
